import { createMyClientCallListApi, getMyClientCallListApi, getHighSeasClientStochasticCallListApi } from '@/api/my_client';
import TurnCallDialog from '@/components/TurnCallDialog';
export default {
    data() {
        return {
            showTurnCallDialog: false,
        };
    },
    methods: {
        // 获取我的客户轮呼列表
        getMyClientCallList() {
            getMyClientCallListApi().then((res) => {
                console.log(res);
                if (res.code !== '0') return console.log('获取我的客户轮呼列表失败');
                if (res.data.list.length === 0) {
                    this.createMyClientCallList();
                } else {
                    this.showTurnCallDialog = true;
                }
            });
            // this.showTurnCallDialog = true
        },
        // 创建我的客户轮呼列表
        createMyClientCallList() {
            let list = [];
            this.client_Ids.forEach((item) => {
                list.push({
                    id: item.id,
                    name: item.name,
                });
            });
            if (list.length === 0) {
                return this.$message.error('当前所选列表为空');
            }
            createMyClientCallListApi(list).then((res) => {
                if (res.code !== '0') return console.log('创建我的客户轮呼列表失败');
                this.jumpTurnCallPage();
            });
        },
        // 获取公海客户随机轮呼列表
        getHighSeasClientStochasticCallList() {
            getHighSeasClientStochasticCallListApi(0).then((res) => {
                if (res.data.list.length === 0) {
                    this.callSizeDialog = true;
                } else {
                    this.showTurnCallDialog = true;
                }
            });
            // this.showTurnCallDialog = true
        },
        // 使用新轮呼列表
        useNewList() {
            this.showTurnCallDialog = false;
            if (this.pageType === 0) {
                this.createMyClientCallList();
            } else {
                this.callSizeDialog = true;
            }
        },
        // 使用旧轮呼列表
        useOldList() {
            this.showTurnCallDialog = false;
            this.jumpTurnCallPage();
            console.log('使用旧轮呼列表');
        },
        // 跳转轮呼页面
        jumpTurnCallPage() {
            this.$router.push({
                path: '/turns-call-list',
                query: {
                    type: 'client',
                    pageType: this.pageType,
                    previousPath: this.$route.path,
                    size: this.radio || 0,
                },
            });
        },
    },
    components: {
        TurnCallDialog,
    },
};
